import { createGlobalStyle } from "styled-components"
import modernNormalize from "styled-modern-normalize"
import PlainSkeleton from "../assets/fonts/Plain-Skeleton.woff"
import PlainRegular from "../assets/fonts/Plain-Regular.woff"
import PlainMedium from "../assets/fonts/Plain-Medium.woff"
import PlainBold from "../assets/fonts/Plain-Bold.woff"
import PlainThin from "../assets/fonts/Plain-Thin.woff"
import PlainLight from "../assets/fonts/Plain-Light.woff"


const GlobalStyle = createGlobalStyle`
  
  // Import normalize.css
  ${modernNormalize}

  @-webkit-keyframes spin {
    0% {
      transform: rotate(0deg)
    }
    to {
      transform: rotate(1turn)
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg)
    }
    to {
      transform: rotate(1turn)
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(0.99);
      box-shadow: 0 0 0 0 rgba(0, 157, 197, 0.7);
    }
  
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 157, 197, 0);
    }
  
    100% {
      transform: scale(0.99);
      box-shadow: 0 0 0 0 rgba(0, 157, 197, 0);
    }
  }

  @media (min-width:1200px) {
    :root {
      font-size: 11.25px
    }
  }

  @media (min-width:1300px) {
    :root {
      font-size: 12.2px
    }
  }

  @media (min-width:1400px) {
    :root {
      font-size: 13.1px
    }
  }

  @media (min-width:1500px) {
    :root {
      font-size: 13.72px
    }
  }

  @media (min-width:1600px) {
    :root {
      font-size: 15px
    }
  }

  @media (min-width:1700px) {
    :root {
      font-size: 16px
    }
  }

  @media (min-width:1800px) {
    :root {
      font-size: 16.87px
    }
  }

  @media (min-width:1900px) {
    :root {
      font-size: 18px
    }
  }

  @font-face {
    font-family: plain-skeleton;
    src: local('Pacifico Regular'), url(${PlainSkeleton}) format("woff");
    font-display: swap;
  }

  @font-face {
    font-family: plain-regular;
    src: local('Pacifico Regular'), url(${PlainRegular}) format("woff");
    font-display: swap;
  }

  @font-face {
    font-family: plain-medium;
    src: local('Pacifico Regular'), url(${PlainMedium}) format("woff");
    font-display: swap;
  }

  @font-face {
    font-family: plain-bold;
    src: local('Pacifico Regular'), url(${PlainBold}) format("woff");
    font-display: swap;
  }

  @font-face {
    font-family: plain-thin;
    src: local('Pacifico Regular'), url(${PlainThin}) format("woff");
    font-display: swap;
  }

  @font-face {
    font-family: plain-light;
    src: local('Pacifico Regular'), url(${PlainLight}) format("woff");
    font-display: swap;
  }

  @keyframes marquee {
    0% { left: 0; }
    100% { left: -100%; }
  }
  
  * {
    box-sizing: border-box;
  }

  html {
    width: 100%;
    height: 100%;
  }

  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.text};
    font-size: 1rem;
    font-family: ${({ theme }) => theme.fonts.plainRegular}, 
        Roboto, Arial, sans-serif;
    line-height: 1.5rem;
    font-weight: 400;
    text-rendering: optimizeLegibility;
    &.blur {
        overflow: hidden;
        #___gatsby #main-content > * {
          filter: blur(5px) ;
          transition: all .3s ease-out;
          pointer-events: none;
          user-select: none;
        }
      }
    }
    &.splashScreen {
      position: fixed;
      overflow: hidden;
    }

  a {
    color: ${({ theme }) => theme.colors.secondary};
    display: inline-block;
    text-decoration: none;
    text-decoration-skip-ink: auto;
    // color: inherit;
    cursor: pointer;
    &:hover,
    &:focus {
      outline: 0;
      opacity: 0.9;
    }
  }

  h1, h2, h3, h4 {
    margin: 0;
  }

  h1 {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.375rem;
    color: ${({ theme }) => theme.colors.primary};
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: 3.1rem;
        line-height: normal;    
    }
  }

  h2 {
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 1.8rem;
    color: ${({ theme }) => theme.colors.primary};
    padding: 0 15px;
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: 1.7rem;
        line-height: normal;
        padding: 0;
    }
  }

  h3 {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 3rem;
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 1.75rem;
      line-height: 2.25rem;
    }
  }

  h4 {
    font-size: 1.1rem;
    line-height: 1.3rem;
    font-weight: 700;
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 1.3rem;
      line-height: 1.5rem;
    }
}

  hr {
    margin: 3rem auto;
    border-width: .05rem;
    color: ${({ theme }) => theme.colors.tertiary};
    opacity: 0.1;
  }

  .section-label {
    font-size: 1.2rem;
    line-height: 1.75rem;
    letter-spacing: -.05rem;
    color: ${({ theme }) => theme.colors.secondary};
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .section-title {
    margin-bottom: 3rem;
    text-align: center;
  }

  .text {
    font-family: ${({ theme }) => theme.fonts.plainLight};
  }

  .text-bold {
    font-weight: bold;
  }

  .text-center {
    text-align: center;
  }

  .mb {
    &-075 {
      margin-bottom: 0.75rem;
    }
    &-1 {
      margin-bottom: 1rem;
    }
    &-2 {
      margin-bottom: 2rem;
    }
  }

  .mt {
    &-075 {
      margin-top: 0.75rem;
    }
    &-1 {
      margin-top: 1rem;
    }
    &-2 {
      margin-top: 2rem;
    }
  }

  .mr {
    &-075 {
      margin-right: 0.75rem;
    }
    &-1 {
      margin-right: 1rem;
    }
    &-2 {
      margin-right: 2rem;
    }
  }

  .ml {
    &-05 {
      margin-left: 0.5rem;
    }
    &-075 {
      margin-left: 0.75rem;
    }
    &-1 {
      margin-left: 1rem;
    }
    &-2 {
      margin-left: 2rem;
    }
  }

  .tab-list {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    margin-top: 4.5rem;
    cursor: pointer;
    .tab-list-item {
      white-space: nowrap;
      margin: 0;
      margin-right: 1rem;
      &:last-of-type {
        margin-right: 0;
      }
      font-size: 0.8rem;
      margin-top: 1rem;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: .05rem;
      position: relative;
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: .9rem;
        &:last-of-type {
          margin-right: 0;
        }
        margin: 0 1rem;
      }
      &.tab-list-active {
        color: ${({ theme }) => theme.colors.tertiary}
      }
      &::before {
        transition: 200ms ease-out;
        height: 0.1563rem;
        content: "";
        position: absolute;
        background-color: ${({ theme }) => theme.colors.tertiary};
        width: 0%;
        bottom: -0.4rem;
      }
      &:hover::before {
        width: 100%;
      }
      &.tab-list-active::before {
        width: 100%;
      }
    }
  }
  
  .gradient {
    background: linear-gradient(357.83deg, rgba(0, 207, 225, 0) 1.57%, rgba(0, 207, 225, .06) 40.64%, rgba(255, 255, 255, .02) 99.79%);
    mix-blend-mode: normal;
  }

  .button-tertiary {
    background: ${({ theme }) => theme.colors.tertiary};
    color: ${({ theme }) => theme.colors.white};
    transition: 20ms ease-out;
    &:hover {
      opacity: 0.8;
      text-decoration: none;
      transition: opacity 0.6s ease-out;
    }
  }
  
  .section-featured {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 1rem;
    &.mobile {
      margin-bottom: 2rem;
      .col-list__wrapper {
        flex-direction: column;
      }
      .visual-item {
        img {
          width: 100%;
        }
      }
    }
  }

  .section-featured a:hover {
    opacity: 0.7;
    text-decoration: none;
  }

  .section-featured .visual-item {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .section-featured .visual-item .play__wrapper {
    position: absolute!important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex!important;
    align-items: center;
    justify-content: center;
  }

  .section-featured .visual-item .play__wrapper a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .section-featured .visual-item .play__wrapper .play-image {
    width: 5rem;
    height: 5rem;
    margin-top: -3rem;
  }


  .section-featured .visual-item img {
    border-radius: 10px;
    width: 24rem;
    height: auto;
    margin-bottom: 1rem;

  }

  .section-featured .visual-item .headline{
    text-align: left;
    color: #34cbe6;
    font-weight: 700;
    text-decoration: underline;
    letter-spacing: -.005rem;
  }

  .section-featured .title {
    letter-spacing: -.005rem;
    font-size: 0.7rem;
    line-height: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    color: rgb(115, 122, 150);
  }

  .section-featured__list {
    margin: 0;
    padding:0;
    list-style: none;
  }
  .section-featured__list-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1.5rem;
  }
  .dropdown-menu.floatLeft {
    left: -19rem!important;
  }
  .dropdown-menu__right .divider {
    border-bottom: 1px solid #d5daf0;
    margin: 2rem 0;
  }
  .section-featured__list-item.columns {
    flex-direction: column;
    margin-right: 6rem;
    margin-top: 0;
    align-items: flex-start;
  }

  .section-featured__list-item.list {
    align-items: flex-start;
  }

  .section-featured__list-item .block {
    flex-direction: column;
    margin-top: 1rem;
  }

  .section-featured__list-item .block .block__item {
    display: flex;
    line-height: 1rem;
    letter-spacing: -.005rem;
    color: #00495d;
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 0.8rem;
  }
  .section-featured__list-item .block .block__item .logo {
    color: #4BADAD;
    margin-right: 0.5rem;
    width: 1.2rem;
    font-size: 1rem;
  }
  .section-featured__list-item .block .block__item .headline {
    white-space: nowrap;
  }
  .section-featured__list-item--logo {
    width: 2.5rem;
    height: 2.5rem;
    min-width: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgba(0, 206, 224, 0.1);
    color: #2D97B3;
    font-size: 1.1rem;
    margin-right: 0.5rem;
  }
  .mega__dd .dropdown-menu__bubble.double .dropdown-menu__right .section-featured__list-item--logo {
    background: transparent;
    width: unset;
    height: unset;
    color: #4BADAD;
  }

  .mega__dd .dropdown-menu__bubble .content .col-list__wrapper  .section-featured__list-item--logo  {
    background: transparent;
    width: unset;
    height: unset;
    color: #4BADAD;
  }

  .section-featured__list-item--logo img {
    width: 1.1rem;
    height: auto;
  }
  .section-featured__list-item--content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-decoration: none;
    font-family: ${({ theme }) => theme.fonts.plainRegular};
  }
  .section-featured__list-item--content .headline {
    font-size: 0.9rem;
    font-weight: 700;
    line-height: 1rem;
    letter-spacing: -.005rem;
    color: #00495d;
    margin-bottom: 0.5rem;
  }
  .section-featured__list-item--content .subheadline {
    line-height: 0.9rem;
    font-size: 0.8rem;
    letter-spacing: -.005rem;
    color: #4BADAD;
    opacity: 0.8;
    text-align: left;
  }

  .section-featured__list-item--content ul.subheadline {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: left;
    list-style: disc;
    padding-left: 1rem;
  }

  .section-featured__list-item--content ul.subheadline li {
    margin-top: 0.3rem;
  }

  .col-list__wrapper {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-top: 1rem;
  }

  .col-list__wrapper .list__wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .col-list__wrapper .list__wrapper li {
    margin-top: 0.5rem;
    font-size: 0.8rem;
    font-weight: 60;
    line-height: 1rem;
    letter-spacing: -.05rem;
    color: #00495d;
  }

  .loader {
    border: 2px solid #fff;
    border-top-color: transparent;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    display: block;
    margin: auto;
  }

  .img-help {
    color: #e91e63; 
    font-family: monospace;
    white-space: nowrap;
 }
 
 .img-help:after {
    font-family: Arial, sans-serif;
    text-align: left;
    white-space: normal;
 }
 
 .img-help:focus {
    outline: none;
 }

    /*== start of code for tooltips ==*/
  .tool {
      cursor: help;
      position: relative;
      text-align: center;
  } 
  /*== common styles for both parts of tool tip ==*/
  .tool::before,
  .tool::after {
      left: 50%;
      opacity: 0;
      position: absolute;
      z-index: -100;
  }

  .tool:hover::before,
  .tool:focus::before,
  .tool:hover::after,
  .tool:focus::after {
      opacity: 1;
      transform: scale(1) translateY(0);
      z-index: 100; 
  }


  /*== pointer tip ==*/
  .tool::before {
      border-style: solid;
      border-width: 1em 0.75em 0 0.75em;
      border-color: #3E474F transparent transparent transparent;
      bottom: 100%;
      content: "";
      margin-left: -0.5em;
      transition: all .65s cubic-bezier(.84,-0.18,.31,1.26), opacity .65s .5s;
      transform:  scale(.6) translateY(-90%);
  } 

  .tool:hover::before,
  .tool:focus::before {
      transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
  }


  /*== speech bubble ==*/
  .tool::after {
      background: #3E474F;
      border-radius: .25em;
      bottom: 130%;
      color: #EDEFF0;
      content: attr(data-tip);
      margin-left: -8.75em;
      padding: 1em;
      transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
      transform:  scale(.6) translateY(50%);  
      width: 17.5em;
  }

  .tool:hover::after,
  .tool:focus::after  {
      transition: all .65s cubic-bezier(.84,-0.18,.31,1.26);
  }
`

export default GlobalStyle
