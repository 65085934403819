module.exports = {
  //-- SITE SETTINGS -----
  author: "@hrflow.ai",
  siteTitle: "HrFlow.ai | Human Resources Data Platform (HRDP)",
  siteShortTitle: "Gatsby", // Used as logo text in header, footer, and splash screen
  siteDescription:
    "HrFlow.ai CDI.",
  siteUrl: "https://hrflow.ai/",
  siteLanguage: "en_US",
  siteIcon: "content/favicon.png", // Relative to gatsby-config file
  seoTitleSuffix: "Human Resources Data Platform (HRDP)", // SEO title syntax will be e.g. "Imprint - {seoTitleSuffix}"
  useCookieBar: false, // If you use Google Analytics and want to be GDPR-compliant, set it to true
  googleAnalyticsTrackingId: "", // e.g. UA-XXXXXX-X

  // -- THEME SETTINGS -----
  colors: {
    lightTheme: {
      primary: "#00495d",
      secondary: "#34cbe6",
      tertiary: "#47b881",
      text: "#00495d",
      white: "#FFFFFF",
      subtext: "#555555",
      background: "#0a2540",
      card: "#FFFFFF",
      scrollBar: "rgba(0, 0, 0, 0.5)",
      boxShadow: "rgba(0, 0, 0, 0.16)",
      boxShadowHover: "rgba(0, 0, 0, 0.32)",
    },
    darkTheme: {
      primary: "#FAFAFA",
      secondary: "#2A2926",
      tertiary: "#252525",
      text: "rgba(255, 255, 255, 0.87)",
      subtext: "#AAAAAA",
      background: "#121212",
      card: "#1C1C1C",
      scrollBar: "rgba(255, 255, 255, 0.5)",
      boxShadow: "rgba(0, 0, 0, 0.16)",
      boxShadowHover: "rgba(0, 0, 0, 0.32)",
    },
  },
  fonts: {
    plainSkeleton: "plain-skeleton, sans-serif",
    plainRegular: "plain-regular, sans-serif",
    plainMedium: "plain-medium, sans-serif",
    plainBold: "plain-bold, sans-serif",
    plainThin: "plain-thin, sans-serif",
    plainLight: "plain-light, sans-serif",
  },

  //-- ARTICLES SECTION SETTINGS -----
  // You can create your own Medium feed with this rss to json converter: https://rss2json.com/
  // To access your Medium RSS feed, just replace this url with your username: https://medium.com/feed/@{yourname}
  mediumRssFeed:
    "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40konstantinmuenster",
  // rssFeed: "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.theguardian.com%2Finternational%2Frss",

  shownArticles: 3,

  //-- SOCIAL MEDIA SETTINGS -----
  // There are icons available for the following platforms:
  // Medium, GitHub, LinkedIn, XING, Behance, E-Mail
  socialMedia: [
    {
      name: "Linkedin",
      url: "https://www.linkedin.com/in/konstantin-muenster/",
    },
    {
      name: "Medium",
      url: "https://konstantinmuenster.medium.com/",
    },
    {
      name: "Github",
      url:
        "https://github.com/konstantinmuenster/gatsby-starter-portfolio-minimal",
    },
    {
      name: "Behance",
      url: "https://www.behance.net/konstanmnster",
    },
    // {
    //   name: "Mail",
    //   url: "mailto:mail@konstantin.digital",
    // },
  ],

  //-- NAVIGATION SETTINGS -----
  navLinks: {
    menu: [
      {
        name: "Product",
        url: "/#about",
      },
      {
        name: "Customers",
        url: "/#projects",
      },
      {
        name: "Resources",
        url: "/#contact",
      },
      {
        name: "Company",
        url: "/#contact",
      },
    ],
  },
  footerLinks: [
    {
      name: "Privacy",
      url: "/privacy",
    },
    {
      name: "Imprint",
      url: "/imprint",
    },
  ],
}
